
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Home",
  setup() {
    const image = ref(true);

    return {
      image,
    };
  },
});
