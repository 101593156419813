
import { defineComponent } from "vue";
import DisqusSection from "../../components/DisqusSection.vue";

export default defineComponent({
  name: "Blog12",
  components: {
    DisqusSection,
  },
});
