
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Photos",
  setup() {
    const october2022 = ref(false);
    const october2022label = ref("Show");
    function showHideOctober2022() {
      october2022label.value = october2022.value ? "Show" : "Hide";
      october2022.value = !october2022.value;
    }

    return {
      october2022,
      october2022label,
      showHideOctober2022,
    };
  },
});
